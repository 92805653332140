:root {
  /* FONTS */
  --font-size-root: 14px;
  --font-family: "Gotham Book", arial, sans-serif;
  --font-family-medium: "Gotham Medium";

  /* Z-INDEX */
  --z-index-highest: 2147483647;
}

:root {
  --tt-background-color: #000;
  --tt-border-color: #000;
}

.Tooltip {
  background-color: #000;
  background-color: var(--tt-background-color);
  border-radius: 3px;
  border: 1px solid #000;
  border: 1px solid var(--tt-border-color);
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin: 0.4rem;
  padding: 0.4rem;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
  z-index: 2147483647;
  z-index: var(--z-index-highest);
}

.Tooltip__arrow {
  height: 1rem;
  position: absolute;
  width: 1rem;
}

.Tooltip__arrow::before {
  border-style: solid;
  content: "";
  display: block;
  height: 0;
  margin: auto;
  width: 0;
}

.Tooltip__arrow::after {
  border-style: solid;
  content: "";
  display: block;
  height: 0;
  margin: auto;
  position: absolute;
  width: 0;
}

.Tooltip__arrow[data-placement*="bottom"] {
  height: 1rem;
  left: 0;
  margin-top: -0.4rem;
  top: 0;
  width: 1rem;
}

.Tooltip__arrow[data-placement*="bottom"]::before {
  border-color: transparent transparent #000 transparent;
  border-color: transparent transparent var(--tt-border-color) transparent;
  border-width: 0 0.5rem 0.4rem 0.5rem;
  position: absolute;
  top: -1px;
}

.Tooltip__arrow[data-placement*="bottom"]::after {
  border-color: transparent transparent #000 transparent;
  border-color: transparent transparent var(--tt-background-color) transparent;
  border-width: 0 0.5rem 0.4rem 0.5rem;
}

.Tooltip__arrow[data-placement*="top"] {
  bottom: 0;
  height: 1rem;
  left: 0;
  margin-bottom: -1rem;
  width: 1rem;
}

.Tooltip__arrow[data-placement*="top"]::before {
  border-color: #000 transparent transparent transparent;
  border-color: var(--tt-border-color) transparent transparent transparent;
  border-width: 0.4rem 0.5rem 0 0.5rem;
  position: absolute;
  top: 1px;
}

.Tooltip__arrow[data-placement*="top"]::after {
  border-color: #000 transparent transparent transparent;
  border-color: var(--tt-background-color) transparent transparent transparent;
  border-width: 0.4rem 0.5rem 0 0.5rem;
}

.Tooltip__arrow[data-placement*="right"] {
  height: 1rem;
  left: 0;
  margin-left: -0.7rem;
  width: 1rem;
}

.Tooltip__arrow[data-placement*="right"]::before {
  border-color: transparent #000 transparent transparent;
  border-color: transparent var(--tt-border-color) transparent transparent;
  border-width: 0.5rem 0.4rem 0.5rem 0;
}

.Tooltip__arrow[data-placement*="right"]::after {
  border-color: transparent #000 transparent transparent;
  border-color: transparent var(--tt-background-color) transparent transparent;
  border-width: 0.5rem 0.4rem 0.5rem 0;
  left: 6px;
  top: 0;
}

.Tooltip__arrow[data-placement*="left"] {
  height: 1rem;
  margin-right: -0.7rem;
  right: 0;
  width: 1rem;
}

.Tooltip__arrow[data-placement*="left"]::before {
  border-color: transparent transparent transparent #000;
  border-color: transparent transparent transparent var(--tt-border-color);
  border-width: 0.5rem 0 0.5rem 0.4em;
}

.Tooltip__arrow[data-placement*="left"]::after {
  border-color: transparent transparent transparent #000;
  border-color: transparent transparent transparent var(--tt-background-color);
  border-width: 0.5rem 0 0.5rem 0.4em;
  left: 3px;
  top: 0;
}
