.button {
  font-family: Gotham;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: var(--brand-white);
  background-color: var(--brand-black);
  height: 32px;
  border: 0px none;
  padding: 0px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 0px;
}

.icon {
  display: inline-block;
  width: 25px;
  height: 25px;
}

.button-secondary {
  border: solid 1px var(--black-10);
  background-color: var(--brand-white);
  color: var(--black-70);
}

.button-secondary--noBorder {
  border: none;
}

.button-tertiary {
  border: 0px none;
  background-color: var(--brand-white);
  color: var(--black-70);
}

.button-primary:hover:enabled {
  background-color: var(--black-50);
}

.button-secondary:hover:enabled,
.button-tertiary:hover:enabled {
  background-color: var(--black-5);
}

.button-primary:active:enabled,
.button-secondary:active:enabled,
.button-tertiary:active:enabled {
  background-color: var(--brand-black);
}

.button-disabled,
.button-disabled:hover,
.button-disabled:focus,
.button-disabled:active {
  color: var(--black-10);
}

.button-primary.button-disabled {
  background-color: var(--black-5);
}

.button-icon {
  margin-left: 6px;
  margin-right: 6px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.button-copy {
  margin-right: 26px;
  margin-left: 26px;
  width: 100%;
}

.button-icon + .button-copy {
  margin-left: 0;
}
