:root {
  /* FONTS */
  --font-size-root: 14px;
  --font-family: "Gotham Book", arial, sans-serif;
  --font-family-medium: "Gotham Medium";

  /* Z-INDEX */
  --z-index-highest: 2147483647;
}

.Modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2147483647;
  z-index: var(--z-index-highest);
}

.Modal__overlay {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  cursor: pointer;
}

.Modal__box {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  padding: 40px;
  min-width: 500px;
  background: white;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 12px 0 rgba(0, 0, 0, 0.05);
          box-shadow: 0 1px 12px 0 rgba(0, 0, 0, 0.05);
  cursor: default;
}

.Modal__box--small {
  padding: 24px;
}

.Modal__title {
  margin-top: 0;
  margin-bottom: 24px;
  font-family: Gotham Medium;
  font-size: 32px;
  font-weight: 500;
  text-align: center;
}

.Modal__title--small {
  margin-bottom: 16px;
  font-family: Gotham Medium;
  font-size: 18px;
  font-weight: 500;
  text-align: left;
}
