:root {
  --light-red: #e94743;
  --brand-red: #df1b12;
  --dark-red: #b1110e;
  --light-teal: #65a7a9;
  --brand-teal: #008d8d;
  --dark-teal: #006967;
  --light-indigo: #5d7ca8;
  --brand-indigo: #004b7f;
  --dark-indigo: #002c5e;
  --dark-pink: #ec619f;
  --brand-pink: #f29ac2;
  --light-pink: #f8c9df;
  --light-yellow: #ffdc7a;
  --brand-yellow: #fdc530;
  --dark-yellow: #f9b023;
  --brand-white: #ffffff;
  --black-2: #fbfbfb;
  --black-5: #f2f2f2;
  --black-10: #e5e5e5;
  --black-25: #bebebe;
  --black-50: #7a7e80;
  --black-70: #4a4c4c;
  --brand-black: #000000;
  --light-blue: #bcdcf5;
  --brand-blue: #8dc3eb;
  --dark-blue: #61ade0;
}

.Input {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-line-pack: center;
      align-content: center;
  min-height: 32px;
  min-width: 120px;
  cursor: pointer;
  position: relative;
}

.Input.Input--active:focus {
  outline: none;
}

.Input__input {
  display: block;
  height: 100%;
  width: 100%;
  padding: 10px 8px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border: solid 1px #e5e5e5;
  border: solid 1px var(--black-10);
  border-radius: 3px;
  font-family: Gotham;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
}

.Input--active > .Input__input,
.Input > .Input__input:focus {
  outline: none;
  border: solid 1px #61ade0;
  border: solid 1px var(--dark-blue);
}

.Input--error > .Input__input {
  border: 1px solid #df1b12;
  border: 1px solid var(--brand-red);
}

.Input__input::-webkit-input-placeholder {
  font-family: Gotham;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #bebebe;
  color: var(--black-25);
}

.Input__input::-moz-placeholder {
  font-family: Gotham;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #bebebe;
  color: var(--black-25);
}

.Input__input:-ms-input-placeholder {
  font-family: Gotham;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #bebebe;
  color: var(--black-25);
}

.Input__input::-ms-input-placeholder {
  font-family: Gotham;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #bebebe;
  color: var(--black-25);
}

.Input__input::placeholder {
  font-family: Gotham;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #bebebe;
  color: var(--black-25);
}

.Input__icon {
  position: absolute;
  left: 6px;
  top: 50%;
  margin-top: -10px;
}

.Input--icon > .Input__input {
  padding-left: 28px;
}
